import { useState, useRef } from "react"
import Button from "./components/Button"

function App() {
  const inputRef = useRef(null)
  const resultRef = useRef(null)
  const [result, setResult] = useState(0)

  function add(e) {
    e.preventDefault()
    setResult((result) => result + Number(inputRef.current.value))
  }

  function subtract(e) {
    e.preventDefault()
    setResult((result) => result - Number(inputRef.current.value))
  }

  function multiply(e) {
    e.preventDefault()
    setResult((result) => result * Number(inputRef.current.value))
  }

  function divide(e) {
    e.preventDefault()
    setResult((result) => result / Number(inputRef.current.value))
  }

  function resetInput(e) {
    e.preventDefault()
    inputRef.current.value = 0
  }

  function resetResult(e) {
    e.preventDefault()
    setResult((result) => result * 0)
  }

  return (
    <div className="App">
      <div>
        <h1>Simplest Working Calculator</h1>
      </div>
      <form>
        <p ref={resultRef}>
          {result}
        </p>
        <input
          pattern="[0-9]"
          ref={inputRef}
          type="number"
          placeholder="Type a number"
        />
        <div style={{paddingTop: 10}}>
          <Button text={"add"} onClick={add} />
          <Button text={"subtract"} onClick={subtract} />
          <Button text={"multiply"} onClick={multiply} />
          <Button text={"divide"} onClick={divide} />
          <Button text={"reset input"} onClick={resetInput} isReset />
          <Button text={"reset result"} onClick={resetResult} isReset />
        </div>
      </form>
    </div>
  )
}

export default App 
